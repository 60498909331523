
    /*  ===== Dashboard Cards  ===== */

    .sk-page-header {
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      flex-wrap: wrap !important;
    }

    .main-panel,
    .dashboard-layout .sidebar .sidebar-wrapper .nav .active a,
    .fullscreen-layout .sidebar .sidebar-wrapper .nav .active a,
    .dashboard-layout .sidebar .sidebar-wrapper .nav .active-before,
    .fullscreen-layout .sidebar .sidebar-wrapper .nav .active-before,
    .dashboard-layout .sidebar .sidebar-wrapper .nav .active-after,
    .fullscreen-layout .sidebar .sidebar-wrapper .nav .active-after {
      background-color: #f6f5f4 !important;
    }

    .website .col-sm-12:not(.sk-connect-website-col) {
      padding: 30px 45px !important;
      display: flex !important;
      flex-direction: row-reverse !important;
      justify-content: space-between !important;
      flex-wrap: wrap !important;
    }

    .sk-connect-website-col.col-sm-12 {
      padding: 0px !important;
    }

    .card,
    .modal-content {
      border-radius: 3px !important;
      border: 1px solid #e4e2ea !important;
      background-color: #fff !important;
    }

    .subscription-header {
      padding: 0px !important;
      border-bottom: 1px solid #262626 !important;
    }

    .subscription-header>div {
      padding: 15px 40px !important;
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      flex-wrap: wrap !important;
    }

    .subscription-header h2 {
      color: #262626 !important;
      margin: 5px !important;
    }

    .subscription-header button {
      margin: 5px !important;
    }

    .dashboard-layout .sidebar .sidebar-wrapper .nav .active i {
      color: #ece70f !important;
    }

    .subscriptionExpiredText {
      width: 100% !important;
      margin: 5px !important;
    }

    @media (min-width: 991px) {
      .dashboard-layout .sidebar {
        width: 340px;
      }
    }

    .dashboard-layout .subscription {
      padding: 30px 50px !important;
    }

    .dashboard-layout .account-view .row {
      border-color: transparent !important;
    }

    .dashboard-layout .website {
      display: flex !important;
      flex-direction: column !important;
    }

    .sk-connect-website-row .sk-connect-website-col {
      display: flex !important;
    }

    .sk-connect-website-row .sk-connect-website-col h3,
    .sk-connect-website-row .sk-connect-website-col span {
      width: 100% !important;
      text-align: left !important;
    }

    /*  ======= Indicators  ======= */

    .website .statusIndicator {
      position: absolute !important;
      left: 20px !important;
      top: 39px !important;
    }

    .statusIndicator {
      height: 10px !important;
      width: 10px !important;
      border-radius: 100% !important;
    }

    .statusIndicator.connected {
      background-color: #28a745 !important;
    }

    /*  ======= Sidebar  ======= */

    .dashboard-layout .sidebar .sidebar-wrapper .nav .active a,
    .dashboard-layout .sidebar .sidebar-wrapper .nav .active-before,
    .dashboard-layout .sidebar .sidebar-wrapper .nav .active-after a,
    .dashboard-layout .sidebar .sidebar-wrapper .nav .active-after div {
      background-color: #262626 !important;
      border-radius: 0 !important;
    }

    .sidebar .logo {
      padding: 60px 30px !important;
      max-width: 250px !important;
    }

    .dashboard-layout .sidebar .sidebar-wrapper .nav {
      width: 100% !important;
    }

    .sidebar ul {
      padding: 0 !important;
      margin: 0 !important;
    }

    .sidebar li.active p:after {
      padding-bottom: 13px !important;
      content: "" !important;
      width: 100% !important;
      margin: auto !important;
      border-bottom: 2px solid #ece70f !important;
      display: block !important;
      position: absolute !important;
    }

    .dashboard-layout .sidebar .sidebar-wrapper .nav li a {
      font-size: 1.3em !important;
    }

    @media (min-width: 991px) {
      .dashboard-layout .main-panel {
        width: calc(100% - 340px);
      }
    }

    /* ==== Fonts ==== */

    .subscriptionTitle {
      -color: #f6f5f4 !important;
      text-transform: uppercase !important;
      font-size: 2em !important;
    }

    h2 {
      font-size: 2em !important;
      padding-bottom: 0 !important;
    }

    h3 {
      font-size: 1.5em !important;
    }

    p {
      font-size: 1.2em !important;
    }

    .sk-page-title {
      font-size: 1em !important;
      font-size: 0.8em !important;
    }

    #navbarDropdownMenuLink:hover {
      opacity: 0.8 !important;
    }

    .nav p {
      color: #f6f5f4 !important;
    }

    /*  ======= Dropdown  =======  */

    .dropdown-menu button {
      background: #f6f5f4 !important;
    }

    .dropdown-menu button:hover {
      background-color: #262626 !important;
    }

    .websiteMenu .dropdown .dropdown-toggle {
      width: 36px !important;
      height: 36px !important;
    }

    .dropdown-item {
      font-weight: 500 !important;
    }

    /* ===== Pricing Tables ======= */

    .get-started .col-lg-3 {
      padding: 0 10px !important;
    }

    .get-started h2 {
      padding-bottom: 15px !important;
      font-size: 2.5em !important;
    }

    /*  ======= Plan Colours  ======= */


    .sash.hide-hidden {
      display: none !important;
    }

    .card-pricing.card {
      background-color: #fff !important;
    }

    .nav.nav-tabs .nav-item a.active {
      border-bottom: 1px solid #262626 !important;
    }

    .nav.nav-tabs .nav-item a {
      color: #262626 !important;
    }

    .fullscreen-layout .get-started .btn-group {
      border: 2px solid !important;
      border-radius: 3px !important;
      margin: 0px !important;
    }

    .fullscreen-layout .get-started .btn-group button.btn-charcoal:nth-child(1) {
      border: 0px !important;
      margin: 0px !important;
      padding: 5px 40px !important;
      border-radius: 0 !important;
    }

    .fullscreen-layout .get-started .btn-group button.btn-charcoal:nth-child(2) {
      border: 0px solid #262626 !important;
      margin: 0px !important;
      padding: 10px 40px !important;
      border-radius: 0 !important;
    }

    .fa-times {
      font-size: 2.5em !important;
    }

    .fa-times:before {
      -content: "✖" !important;
    }

    /* ==== Mobile styles ==== */

    .navbar .navbar-toggler-bar {
      background-color: #262626 !important;
    }

    @media (max-width: 991px) {
      .website .col-sm-12 {
        padding: 15px 30px !important;
      }

      .website .sk-connect-website-col.col-sm-12 {
        padding: 0 !important;
      }

      .website .col-sm-12 .websiteMenu,
      .website .col-sm-12 .website-details {
        width: 100% !important;
        flex-direction: column;
      }

      .website .col-sm-12 .websiteMenu {
        margin-left: 0px !important;
        display: flex;
        flex-direction: end !important;
      }

      .website .col-sm-12 .websiteMenu .dropdown {
        display: flex;
        justify-content: flex-end;
      }

      .website .sk-connect-website-col.col-sm-12 h3,
      .website .sk-connect-website-col.col-sm-12 button {
        margin-left: 0 !important;
      }

      .dashboard-layout .subscription {
        padding: 15px 30px !important;
      }

      .dropdown.show.nav-item div {
        margin: 0 !important;
      }

      .dropdown.show.nav-item {
        border-radius: 3px !important;
        border: 1px solid #e4e2ea !important;
        box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
      }

      .dropdown-menu button.dropdown-item {
        background-color: #fff !important;
      }

      .dropdown-menu button.dropdown-item:hover {
        background-color: #262626 !important;
      }

      .statusIndicator {
        display: none;
      }

      .card-body>.row:nth-child(1)>div {
        padding: 10px 20px !important;
      }

      .card-body>.row:nth-child(1)>div h2 {
        font-size: 1.5em !important;
      }
    }

    /* ===== Breakpoints ======= */

    @media (max-width: 1400px) {
      .card-body>.row:nth-child(2) {
        flex-direction: column !important;
      }

      .subscription {
        width: 100% !important;
        max-width: 100% !important;
        order: 1
      }

      .website {
        width: 100% !important;
        max-width: 100% !important;
        order: 2
      }
    }

    @media screen and (max-width: 991px) {
      .navbar .navbar-toggler-bar {
        background-color: #f6f5f4;
      }
    }

    /* ===== Popup ======= */

    .modal-content button .fa:before {
      font-size: 0.8em !important;
    }

    /* === Inactive websites list === */

    #inactive-websites-list .statusIndicator {
      position: absolute !important;
      top: 20px !important;
    }

    #inactive-websites-list .col-lg-6 {
      padding-left: 30px !important;
    }

    #inactive-websites-list p {
      margin-left: 10px !important;
    }

    #inactive-websites-list .col {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      align-content: center !important;
    }

    @media (max-width: 991px) {
      #inactive-websites-list .col-lg-6 {
        padding-left: 15px !important;
      }

      #inactive-websites-list p {
        text-align: center;
      }
    }


    /* === Payment Page === */

    .stripe-payment-form .card-body>.row:nth-child(1) {
      border: none !important;
    }

    /* === Forms === */
    .form-control {
      border-radius: 3px;
    }
