//Reset

.navbar.navbar-transparent .nav-item.active .nav-link:not(.btn),
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):focus,
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):hover,
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):focus:hover,
.navbar.navbar-transparent .nav-item .nav-link:not(.btn):active {
  color: #000;
}

.nav-tabs .nav-item .nav-link.active:before,
.nav-tabs .nav-item .nav-link.active:hover:before,
.nav-tabs .nav-item .nav-link.active:focus:before,
.nav-tabs .nav-item .nav-link.active:after,
.nav-tabs .nav-item .nav-link.active:hover:after,
.nav-tabs .nav-item .nav-link.active:focus:after {
  content: none !important;
  display: none;
}

// Error Fallback
#error-fallback {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0px;
}

// Toastify

.Toastify__toast-theme--colored.Toastify__toast--info,
.alert.alert-info {
  background-color: $sk-sky;
}
.Toastify__toast-theme--colored.Toastify__toast--success,
.alert.alert-success {
  background-color: $sk-apple;
  color: #fff;
}
.Toastify__toast-theme--colored.Toastify__toast--error,
.alert.alert-error {
  background-color: $sk-brick;
}

.alert p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.notification-alert a {
  color: #fff !important;
  text-decoration: underline !important;
  &:hover,
  &:focus {
    color: darken(#fff, 20%) !important;
  }
}

// Tooltips
.large-tooltip {
  .tooltip.show {
    opacity: 1;
  }
  .tooltip-inner {
    max-width: 260px;
    padding: 20px;
  }
}

// Loading Ellipsis Animation
.loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -moz-animation: ellipsis 2s infinite;
  animation: ellipsis 2s infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
}

@-moz-keyframes ellipsis {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  24.9% {
    clip-path: inset(0 100% 0 0);
  }
  25% {
    clip-path: inset(0 66.6% 0 0);
  }
  49.9% {
    clip-path: inset(0 66.6% 0 0);
  }
  50% {
    clip-path: inset(0 33.3% 0 0);
  }
  74.9% {
    clip-path: inset(0 33.3% 0 0);
  }
  75% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes ellipsis {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  24.9% {
    clip-path: inset(0 100% 0 0);
  }
  25% {
    clip-path: inset(0 66.6% 0 0);
  }
  49.9% {
    clip-path: inset(0 66.6% 0 0);
  }
  50% {
    clip-path: inset(0 33.3% 0 0);
  }
  74.9% {
    clip-path: inset(0 33.3% 0 0);
  }
  75% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

// Typography
body {
  color: #262626;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 0;
}

a {
  color: darken($sk-charcoal, 20%);
  &:hover,
  &:focus {
    color: darken($sk-charcoal, 20%);
  }
  transition: 0.25s;
  text-decoration: underline;
}

.navbar-nav a {
  text-decoration: none;
}

p {
  margin: 1rem 0;
}

.text-small {
  font-size: 1rem !important;
}

hr {
  border-top: 2px solid $sk-chalk;
}

.card + .card {
  margin-top: 30px;
}

.fa-external-link {
  margin-left: 10px;
  position: relative;
  top: 2px;
}

a .fa-external-link {
  opacity: 0;
}

a:hover .fa-external-link {
  opacity: 1;
  transition: opacity 0.35s ease-out;
}

// Buttons

.btn {
  padding: 10px 40px;
  white-space: nowrap;
  border-radius: 3px;
  background-color: transparent;

  i {
    margin-right: 10px;
  }

  &:disabled {
    opacity: 0.6;
    color: lighten(#000000, 40%);
  }
}

.btn-wide {
  padding-left: 60px;
  padding-right: 60px;
}

.btn-full-width {
  width: 100%;
  margin: 30px 0;
}

h3 + .btn {
  margin-left: 20px;
  font-size: 1.4rem;
}

.btn + .btn {
  margin-left: 20px;
}

.btn-success,
.btn-info,
.btn-danger,
.btn-ocean,
.btn-charcoal,
.btn-purple {
  font-size: 1rem;
  color: $sk-charcoal;
  text-transform: none;

  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    color: #ffffff !important;
  }
}

.btn-success {
  color: $success-color;
  border: 1px solid $success-color;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $success-color;
    background-color: $success-color !important;
  }
}

.btn-primary {
  font-size: 1rem;
  color: $primary-color;
  text-transform: none;
  border: 1px solid $primary-color;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $primary-color;
    background-color: $primary-color !important;
    color: #fff !important;
  }
}

.btn-info {
  @extend .btn-primary;
}

.btn-danger {
  color: $danger-color;
  border: 1px solid $danger-color;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $danger-color;
    background-color: $danger-color !important;
  }
}

.btn-ocean {
  @extend .btn-primary;
}

.btn-charcoal {
  border: 1px solid $sk-charcoal;
  background-color: $sk-charcoal !important;
  color: #fff;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $sk-charcoal;
    background-color: lighten($sk-charcoal, 10%) !important;
  }
}

.btn-purple {
  border: 1px solid $sk-purple;
  background-color: $sk-purple !important;
  color: #fff !important;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $sk-charcoal;
    background-color: $sk-charcoal !important;
    color: #fff !important;
  }
}

.btn-lemon {
  border: 1px solid $sk-lemon;
  background-color: $sk-lemon !important;
  color: #fff !important;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $sk-charcoal;
    background-color: $sk-charcoal !important;
    color: #fff !important;
  }
}

.signup-with-email-btn {
  margin: 0px auto;
  margin-top: 40px;
  font-size: 1rem;

  .btn,
  span {
    width: 300px;
    border-radius: unset;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;

    &:hover {
      svg {
        fill: #fff;
      }
    }

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 1rem;
    }
  }
}

.auth-divider {
  margin-top: 40px;
  font-size: 1rem;
}

.squarespace-btn {
  margin: 0px auto;
  margin-top: 40px;
  font-size: 1rem;

  .btn,
  span {
    border-radius: unset;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;

    &:hover {
      svg {
        fill: #fff;
      }
    }

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 1rem;
    }
  }
}

.squarespace-login {
  margin: 0px auto;
  margin-top: 40px;
}

.squarespace-connect {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.squarespace-connected {
  display: block;
  background-color: #403d39 !important;
  color: #ffffff !important;
  border: 1px solid #000 !important;
  margin: 10px 1px;
  padding: 10px 40px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 0.8571em;
  line-height: 1.35em;
  text-transform: uppercase;
}

// Plan Colors
.plan-color-FREE_TRIAL {
  color: $sk-lemon;
}
.plan-color-BASIC {
  color: $sk-apple;
}
.plan-color-PRO {
  color: $sk-sky;
}
.plan-color-DESIGNER {
  color: $sk-purple;
}
.plan-color-AGENCY {
  color: $sk-charcoal;
}
.plan-bgcolor-FREE_TRIAL {
  background-color: $sk-lemon;
}
.plan-bgcolor-BASIC {
  background-color: $sk-apple;
}
.plan-bgcolor-PRO {
  background-color: $sk-sky;
}
.plan-bgcolor-DESIGNER {
  background-color: $sk-purple;
}
.plan-bgcolor-AGENCY {
  background-color: $sk-charcoal;
}
.btn-plan-color-FREE_TRIAL {
  @extend .btn-lemon;
  color: $sk-charcoal !important;
  background-color: $sk-lemon;
  padding: 15px 60px;
  text-transform: uppercase;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $sk-charcoal;
    background-color: $sk-charcoal !important;
  }
}
.btn-plan-color-BASIC {
  @extend .btn-success;
  color: #fff;
  background-color: $sk-apple;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $sk-charcoal;
    background-color: $sk-charcoal !important;
  }
}
.btn-plan-color-PRO {
  @extend .btn-info;
  color: #fff;
  border: 1px solid $sk-sky;
  background-color: $sk-sky;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $sk-charcoal;
    background-color: $sk-charcoal !important;
  }
}
.btn-plan-color-DESIGNER {
  @extend .btn-purple;
  color: #fff;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $sk-charcoal;
    background-color: $sk-charcoal !important;
  }
}
.btn-plan-color-AGENCY {
  @extend .btn-charcoal;
  color: #fff;
  &:hover,
  &:active,
  &:focus,
  &:active:hover,
  &:active:focus {
    border: 1px solid $sk-charcoal;
    background-color: lighten($sk-charcoal, 10%);
  }
}

// Free Trial Banner
.free-trial-banner {
  div {
    padding: 30px;
  }

  h1,
  h2,
  h4 {
    color: #ffffff !important;
  }

  h1 {
    text-align: center;
  }

  padding: 60px;
  background-color: $sk-purple;
}

// Status Indicator
.disabled .website-details,
.disabled .statusIndicator {
  opacity: 0.4;
}

.statusIndicator {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  position: relative;
  top: 6px;
  float: left;
  max-width: 100%;
  margin: 0 15px 0 0;

  &.connected {
    background-color: #2ac940;
  }

  &.connecting {
    background-color: #ff0000;

    animation: connecting 1800ms infinite;

    @keyframes connecting {
      0% {
        background-color: rgba(255, 0, 0, 1);
      }
      70% {
        background-color: rgba(255, 0, 0, 1);
      }
      85% {
        background-color: rgba(255, 0, 0, 0.3);
      }
      100% {
        background-color: rgba(255, 0, 0, 1);
      }
    }
  }
}

// Modals

// Website Settings Modal

.website-settings-modal {
  &.modal-dialog {
    padding-top: 15px !important;
  }

  .modal-header {
    background-color: #fff;
    color: $sk-charcoal;
  }

  .close-btn {
    padding: 20px;
    //margin-left: 0;
    position: absolute;
    left: 774px;
    top: -35px;
    border: 1px solid $sk-charcoal;
    color: $sk-charcoal;
    background-color: #fff;
    text-transform: none;
    font-size: 1rem;
    z-index: 100;

    height: 40px;
    width: 40px;

    border-radius: 40px;

    &:hover,
    &:focus,
    &:active {
      border: 1px solid $sk-charcoal;
      background-color: $sk-charcoal !important;
      color: #fff !important;
    }

    i {
      display: flex;
      justify-content: center;
      margin-top: -17px;
    }
  }

  @media screen and (max-width: 991px) {
    .close-btn {
      display: none;
    }
  }

  .modal-body {
    padding: 0;

    .row {
      margin: 0;
      padding: 30px 45px;
      &:not(:first-child) {
        border-top: 1px solid $sk-chalk;
      }
    }
  }

  .modal-footer {
    padding: 15px 30px;

    .row {
      margin: 0;
    }
  }

  .domain-field {
    width: 100%;
    input {
      padding-right: 150px;
    }
    p {
      position: absolute;
      right: 20px;
      margin: 0;
    }
  }

  .form-inline .form-group {
    margin-bottom: 10px;
  }

  fieldset {
    width: 100%;
  }

  .inline-field {
    width: 100%;
    margin-right: 5px;
  }

  textarea {
    font-family: Courier, Courier New, monospace;
    line-height: 22px;
    max-height: unset;
    height: 17em;
    background-color: $sk-chalk !important;
    color: #262626 !important;
    border: 1px solid $sk-charcoal !important;
    cursor: text !important;
  }

  textarea:focus {
    border: 2px solid $sk-charcoal !important;
  }

  .installation-step-icon {
    font-weight: bold;
    line-height: calc(2.5rem - 6px);
    height: 2.5rem;
    width: 2.5rem;
    display: inline-block;
    text-align: center;
    border: 3px solid;
    border-radius: 100%;
    margin-top: 1rem;
    color: $sk-ocean;
  }

  .installation-step-title {
    font-weight: bold;
    display: inline-block;
    margin-top: 1rem;
    margin-left: 1rem;
    color: $sk-ocean;
  }

  .installation-steps {
    margin-left: 3.5rem;
  }

  h5 {
    i[class*='chevron'] {
      float: right;
    }
  }

  h6 {
    margin-right: 30px;
    margin-bottom: 0px;
    font-size: 1.2em;
  }

  p {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .fa-check-circle {
    font-size: 1.6rem;
  }

  // Inactive Websites
  .website-list-search {
    border-bottom: 1px solid $sk-chalk;
  }

  .website-list {
    max-height: 50vh;
    overflow-y: scroll;
  }

  .website-list-row:hover {
    background-color: $sk-chalk;
  }

  .website-list-row .btn {
    opacity: 0;
  }

  .website-list-row:hover .btn {
    opacity: 1;
    transition: opacity 0.35s ease-out;
  }

  .website-list-actions-col {
    display: flex;
    align-items: center;
  }
}

// Forms

.form-split-input {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.form-text {
  font-size: $font-size-small;
}

.btn:disabled {
  visibility: hidden;

  &.no-hide {
    visibility: visible !important;
    color: #fff;
    text-shadow: 0px 0px 3px #666;
  }
}

.form-control {
  background-color: #fff;
  border: 1px solid $sk-charcoal;
  color: $sk-charcoal;

  &:hover,
  &:focus {
    border-color: $sk-charcoal;
  }
}

.has-success .form-control {
  border: 1px solid $sk-apple;
}

fieldset[disabled] .form-control {
  cursor: wait;
}

.form-check {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-check .form-check-sign::before {
  color: #000;
  background-color: #fff;
  border: 1px solid $sk-charcoal;
}

.form-check-label input[type='checkbox']:checked + .form-check-sign:before {
  color: #000;
  background-color: $sk-charcoal;
  border: 1px solid $sk-charcoal;
}

label,
.card label {
  font-weight: bold;
  color: #000000;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.notification-alert {
  padding: 10px;
  ul {
    list-style: none;
    padding-left: 10px;
  }
}

.alert,
.text-danger {
  margin-top: 1rem;
}

.main-panel > .content {
  padding: 0 60px 30px;
  min-height: calc(100vh - 162px);
  margin-top: 93px;
}

// Auth Layout
.auth-layout {
  .login-link,
  .signup-link,
  .forgot-link {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .forgot-link {
    margin-top: 10px;
  }

  .auth-wrapper {
    max-width: 480px;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: center !important;

    .logo {
      padding: 24px 12px 0px;
      margin: 0 auto;
    }

    .page-title {
      margin-top: 30px;
      text-align: center;

      h2 {
        font-weight: bold;
        font-size: 24px;
      }
    }

    .auth-form {
      margin: 40px auto;
      max-width: 320px;

      h2 {
        font-weight: bold;
        font-size: 24px;
      }

      p {
        font-size: 1rem;
      }

      h2 {
        margin-bottom: 20px;
      }

      button {
        color: #000;
      }

      p.text-danger,
      .alert {
        margin-top: 0.5rem;
      }
    }
  }

  .register-with-squarespace,
  .register-with-email {
    .auth-form {
      max-width: 400px;
    }
  }

  @media screen and (min-width: 991px) {
    .main-panel {
      width: 75%;
      max-width: calc(100% - 440px);
      min-height: 100vh;
    }

    .register-page,
    .login-page {
      min-height: calc(100vh - 70px);
      margin-top: 70px;
      .container {
        min-height: calc(100vh - 70px);
      }
    }
  }

  @media screen and (max-width: 991px) {
    .main-panel {
      width: 100%;
      min-height: 100vh;
    }

    .btn-max-size {
      width: 320px;
    }

    .register-page,
    .login-page {
      .container {
        min-height: calc(100vh - 70px);
      }
    }
    .auth-sidebar {
      display: none;
    }
  }

  .auth-sidebar {
    width: 25%;
    min-width: 440px;
    background-color: #000;
    background-size: cover;
    background-position: center;

    &:after {
      background: none;
    }

    // .logo {
    //   width: 400px;
    //   padding: 24px;

    //   &:after {
    //     background: none;
    //   }
    // }

    // .sidebar-wrapper {
    //   display: flex;
    //   width: initial;
    //   overflow: hidden;
    //   .auth-sidebar-text {
    //     // align-self: flex-end;
    //     padding: 80px 40px;
    //     h1 {
    //       color: #fff;
    //       font-size: clamp(50px, 3vw, 3vw);
    //       font-weight: 700;
    //       text-transform: uppercase;
    //     }
    //   }
    // }
  }
}

// Dashboard Layout
.dashboard-layout,
.fullscreen-layout {
  .main-panel {
    background-color: $sk-chalk;
    width: calc(100% - 400px);
  }

  .sidebar {
    * {
      transition: 0s !important;
    }
    width: 400px;
    background-color: $sk-charcoal;

    &:after {
      background: none;
    }

    .logo {
      padding: 60px 60px 40px 60px;

      &:after {
        background: none;
      }
    }

    .sidebar-wrapper {
      width: 100%;
      // position: relative;
      // left: 15px;
      overflow: hidden;

      height: calc(100vh - 150px);

      .nav {
        background-color: $sk-chalk;

        margin: 0px 60px;
        width: calc(100% - 60px);

        a {
          margin: 0;
          padding: 20px 30px;
        }

        li {
          min-height: 20px;
          a,
          i,
          p {
            color: #ffffff;
            text-transform: none;
            font-size: 1.5rem;
            opacity: 1;
          }
        }

        .active {
          background-color: $sk-charcoal;
          & a {
            background-color: $sk-chalk;
            //box-sizing: border-box;
            //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            border-radius: 20px 0 0 20px;
          }

          i,
          p {
            color: #000;
            text-transform: none;
            font-size: 1.5rem;
          }

          a::before,
          a::after {
            content: none !important;
          }
        }

        .active-before {
          background-color: $sk-chalk;
          & div,
          & a {
            min-height: 20px;
            background-color: $sk-charcoal;
            border-radius: 0 0 20px 0;
          }
        }

        .active-after {
          background-color: $sk-chalk;
          & div,
          & a {
            min-height: 20px;
            background-color: $sk-charcoal;
            border-radius: 0 20px 0 0;
          }
        }

        li:not(.active):not(.active-before):not(.active-after) {
          background-color: $sk-charcoal;
        }
      }
    }
  }

  .navbar .container-fluid {
    padding: 15px 30px;
  }

  .navbar .navbar-nav .nav-link,
  .navbar .navbar-nav .nav-link i {
    text-transform: none;
    top: -2px;
    h5 {
      font-weight: 500;
    }
  }

  .wrapper {
    min-height: calc(100vh - 70px);
  }

  .table-responsive {
    overflow: hidden;
  }

  // Dashboard Page
  .dashboard-view,
  .account-view {
    .row {
      margin-bottom: 15px;
    }

    .sk-page-header {
      margin-bottom: 30px;
    }

    .sk-page-title,
    .sk-page-title h1 {
      display: inline;
    }

    .sk-page-actions {
      display: inline;
    }

    .sk-new-subscription-btn {
      float: right;
      text-transform: none;
      font-size: 1rem;
    }

    .sk-get-started h3 {
      text-align: center !important;
    }

    // Subscription

    .card-body {
      padding: 0;

      .row {
        margin: 0;
        div[class^='col'] {
          padding: 30px;
        }
      }

      h3 {
      }

      h4 {
        margin: 0;
      }

      h5 {
        margin: 10px 0;
      }

      h6 {
        margin: 5px 0;
      }

      p {
        font-size: 1.2rem;
        margin: 0 0 4px 0;
      }

      .subscriptionTitle {
        float: left;
      }

      .subscriptionExpiredText {
        clear: both;
        color: $sk-brick;
        font-weight: normal;
      }

      .websiteMenu {
        float: right;
        color: black;
        margin-left: 30px;

        .dropdown {
          font-size: inherit !important;
        }

        .dropdown-item {
          font-size: 1rem;
          font-weight: 600;
        }

        .dropdown-toggle {
          border-radius: 50% !important;
          border: 1px $sk-charcoal solid;
          padding: 0px 10px;
          &::after {
            content: none;
          }
        }
        .dropdown-toggle:hover {
          background: lighten($sk-charcoal, 75%) !important;
          color: lighten($sk-charcoal, 10%) !important;
        }
      }

      .website {
        padding: 0;
        border-right: 1px solid $sk-chalk;

        .row {
          border-top: 1px solid $sk-chalk;

          &:only-of-type {
            height: 100%;
          }
        }
      }

      .subscription {
        padding: 30px;
        border-top: 1px solid $sk-chalk;

        h3 {
          margin-bottom: 0.5rem;
        }
      }

      .website-details {
        float: left;
      }
    }

    .sk-connect-website-row {
      padding: 30px;
      height: auto !important;

      .sk-connect-website-col {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;

        h3 {
          margin: 5px 10px;
        }

        .sk-connect-website-btn {
          margin: 5px 10px;
          text-transform: none;
          font-size: 1rem;
        }
      }
    }
  }

  // Account
  .account-view {
    .card {
      max-width: 992px;
    }

    .row {
      border-top: 1px solid $sk-chalk;
    }
  }

  // Help
  .iframe-container {
    overflow: hidden;
    height: 100vh;
  }

  .iframe-container iframe {
    overflow: hidden;
    border: 0;
    height: 100%;
    width: 100%;
  }

  // Components

  // Dropdown
  .dropdown {
    display: inline;
    font-weight: bold;
    font-size: 24px;

    .btn {
      background-color: transparent !important;
      color: #000 !important;
    }
    .btn:hover {
      color: $sk-lemon !important;
    }
  }

  .dropdown-menu {
    &:before,
    &:after {
      content: none !important;
    }

    padding-left: 0px !important;

    .dropdown-item:hover {
      background-color: $sk-slate;
      a {
        color: #ffffff !important;
      }
    }

    & a {
      font-size: 1.2rem !important;
      font-weight: 400 !important;
      color: #000 !important;
    }
  }

  // Media Queries
  @media screen and (max-width: 767px) {
    .sk-get-started h3 {
      font-size: 1.4rem;
    }
  }

  @media screen and (min-width: 991px) {
    .main-panel {
      width: 75%;
      width: calc(100% - 400px);
      min-height: 100vh;
    }

    .container {
      min-height: calc(100vh - 70px);
    }
  }

  @media screen and (max-width: 991px) {
    .main-panel {
      width: 100%;
      min-height: 100vh;
    }

    .main-panel > .content {
      padding: 0 30px 30px;
    }

    .container {
      min-height: calc(100vh - 70px);
    }

    #sk-get-started-btn {
      margin-left: 0 !important;
    }

    .website {
      border-right: none !important;
    }

    .sidebar {
      width: 260px;
      .sidebar-wrapper {
        .nav {
          margin-left: 20px;
          width: 240px;
          transition-delay: 1s !important;
        }
      }
    }
  }
}

// Fullscreen Layout
.fullscreen-layout {
  .main-panel {
    background-color: $sk-chalk;
    width: 100%;
  }

  .row + .row {
    margin-top: 20px;
  }

  .nav {
    * {
      transition: 0s !important;
    }
    .nav-item {
      .nav-link {
        font-size: 1.5rem;
        font-weight: 700;
        color: $sk-ocean;

        padding-left: 0px;
        padding-right: 0px;
        margin-left: 16px;
        margin-right: 16px;

        &.active {
          border-bottom: 2px solid $sk-ocean;
        }
      }
    }
  }

  // Billing Cycle
  .btn-group {
    .btn {
      background-color: $sk-charcoal !important;
      border: 3px solid $sk-charcoal !important;
    }
    .btn:not(.active) {
      color: $sk-charcoal !important;
      background-color: transparent !important;
    }
  }

  // Plans and Pricing
  h5 {
    font-weight: normal;
  }

  .col-lg-3 {
    flex: 0 0 350px !important;
    max-width: 350px;
  }

  .save-annually {
    font-size: 1rem;
    text-align: center;
    font-weight: bolder;
  }

  .card-pricing {
    overflow: hidden;

    .sash {
      font-size: 0.9rem;
      font-weight: 600;
      position: absolute;
      margin: 0;
      width: 100%;
      top: 36px;
      right: -118px;
      transform: rotateZ(45deg);
      color: #fff;
      transition: 0.25s ease-in-out;

      & .sash-hidden {
        opacity: 0 !important;
      }
    }
    h3 {
      height: 7rem;
      font-size: 5rem;
      padding: 5px;
    }

    .pricing-free {
      font-size: 3rem;
    }

    .billing-cycle {
      margin: 0;
      text-transform: uppercase;
      color: lighten($sk-charcoal, 40%);
    }

    hr {
      border-top: 1px solid $sk-charcoal;
      margin: 10px 60px;
    }

    top: 0;
    transition: 0.25s;
    &:hover:not(.disabled),
    &.selected:not(.disabled) {
      position: relative;
      top: -5px;
      box-shadow: 8px 8px 5px lighten($sk-charcoal, 60%);
    }

    .pricing-currency {
      font-size: 1rem;
      line-height: 5rem;
      vertical-align: super;
    }

    .pricing-price {
      font-size: 5rem;
    }

    .pricing-month {
      line-height: 5rem;
      font-size: 1rem;
    }

    .plan-description {
      margin: 30px 0;
      font-size: 1rem;
    }

    .btn {
      font-size: 1.2rem;
    }

    &.disabled .card-body {
      & > *:not(button) {
        opacity: 0.4;
        filter: grayscale(1);
      }
    }
  }

  // Step 3
  .payment-success {
    margin: 0 15px;

    p {
      font-size: 1rem;
    }
  }
}

.cardholder-name {
  text-transform: uppercase;
  word-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
  font-size: 1rem !important;
  line-height: 25px !important;
}

// Payment Form
.stripe-payment-form {
  @media screen and (min-width: 991px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .card {
    @media screen and (min-width: 991px) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      margin: 15px;
    }
    padding: 15px;
  }

  .payment-method {
    cursor: pointer;
    padding: 10px;
    width: 300px;

    &.selected {
      border: 2px solid $sk-apple;
      border-radius: 12px;
      padding: 8px;
    }

    p {
      line-height: 25px !important;
    }
  }

  form {
    margin: 0;
  }

  h4 {
    margin-top: 0;
  }

  h5 {
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2rem;
    margin: 0 0 4px 0;
  }

  .form-group + h5 {
    margin-top: 30px;
  }

  .form-control {
    background-color: #fff;
  }

  .StripeElement,
  .StripeElementIdeal,
  .StripeElementFpx {
    //display: block;
    //margin: 10px 0 20px 0;
    //max-width: 500px;
    padding: 10px 10px 10px 10px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid $sk-charcoal;
  }

  // .form-control,
  // .StripeElement,
  // .StripeElementIdeal,
  // .StripeElementFpx {
  //   //display: block;
  //   //margin: 10px 0 20px 0;
  //   //max-width: 500px;
  //   padding: 10px 10px 10px 10px;
  //   //box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  //   //border-radius: 8px;
  //   //background-color: #e4e2ea;
  //   border: none;
  //   border-radius: 0;
  //   background: none;
  //   border-bottom: 2px solid #c9c5d6;
  // }

  .StripeElement--focus,
  .StripeElementIdeal--focus,
  .StripeElementFpx--focus {
    border: 2px solid $sk-charcoal;
    box-shadow: none;
    outline: 0 !important;
    color: #66615b;
    background-color: #fff;
  }

  .StripeElement--complete {
    border: 1px solid $sk-apple;
  }

  .has-danger .StripeElement:not(.StripeElement--complete),
  .StripeElement--invalid {
    color: $danger-color;
    border: 1px solid $danger-color;
  }

  .StripeElement.loading {
    height: 41.6px;
    opacity: 0.6;
  }

  .StripeElementIdeal,
  .StripeElementFpx {
    padding: 0;
  }
}
